import { useToasts } from '@/hooks/useToasts'

import {
  IncidentDocument,
  useCreateIncidentActionMutation,
} from '@/graphql/generated/hooks'
import { IncidentActionType } from '@/graphql/generated/schemas'

export const useSendIncidentsToOntic = (incidentId: string) => {
  const { showSuccess, showError } = useToasts()

  const [createIncidentAction, { loading: isSendingToOntic }] =
    useCreateIncidentActionMutation()

  const send = async () => {
    try {
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.SendToOntic,
          },
        },
        refetchQueries: [
          {
            query: IncidentDocument,
            variables: {
              id: incidentId,
            },
          },
        ],
      })

      showSuccess('Incident sent to Ontic successfully')
    } catch (e) {
      showError(e.message)
    }
  }

  return {
    isSendingToOntic,
    send,
  }
}
