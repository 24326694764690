import { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { HiArrowLeft } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
} from '@chakra-ui/react'

import { Button, FormInputSelectControl } from '@/components/ui'
import {
  useFacilityNamesQuery,
  useFloorsByFacilityIdsQuery,
} from '@/graphql/generated/hooks'
import { DeviceType } from '@/graphql/generated/schemas'
import {
  selectDeviceCreatedAtFilter,
  selectDeviceFacilityIdsFilter,
  selectDeviceFloorsIdsFilter,
  selectDeviceHasIncidentsFilter,
  selectDeviceStatusFilter,
  selectDeviceTypeFilter,
  setDeviceFacilityCreatedAtFilter,
  setDeviceFacilityIdsFilter,
  setDeviceFloorsIdsFilter,
  setDeviceStatusFilter,
  setDeviceTypeFilter,
  setDevicesHasIncidentsFilter,
} from '@/redux/ui/uiSlice'
import {
  activeIncidentsOptions,
  createdAtOptions,
  getRelatedFloors,
} from '@/utils/filterDrawers'
import { getFacilityOptions } from '@/utils/forms/optionBuilders'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const deviceStatusOptions = [
  { value: 'HEALTHY', label: 'Healthy', color: '#3279C7' },
  { value: 'UNHEALTHY', label: 'Degraded', color: '#D01030' },
]

const deviceTypeOptions = [
  { value: DeviceType.Door, label: 'Access Control Door' },
  { value: DeviceType.Alarm, label: 'Alarm' },
  { value: DeviceType.Camera, label: 'Camera' },
]

export const DevicesFilterDrawer = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()
  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })
  const { control, handleSubmit, watch, reset, getValues } = useForm({
    reValidateMode: 'onChange',
  })
  const facilityIdsFilterValue = useSelector(selectDeviceFacilityIdsFilter)
  const floorsIdsFilterValue = useSelector(selectDeviceFloorsIdsFilter)
  const createdAtFilterValue = useSelector(selectDeviceCreatedAtFilter)
  const activeIncidentsFilterValue = useSelector(selectDeviceHasIncidentsFilter)
  const deviceStatusFilterValue = useSelector(selectDeviceStatusFilter)
  const deviceTypesFilterValue = useSelector(selectDeviceTypeFilter)
  const watchFacility = watch('facilityName', facilityIdsFilterValue)
  const watchFloor = watch('floorName')
  const ids: string[] = facilityIdsFilterValue?.map((e) => e.value as string)
  const { data: floorsData, refetch } = useFloorsByFacilityIdsQuery({
    fetchPolicy: 'network-only',
    variables: { ids: ids },
  })

  const facilityOptions = getFacilityOptions(facilitiesData)

  const floorsOptions =
    floorsData?.floors?.edges.map((f) => ({
      label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
      value: f?.node?.id,
    })) || []
  const validFloorOptions = watchFacility?.length ? floorsOptions : []

  const onSubmit = async (values) => {
    const selectedDeviceFacilityIds = values?.facilityName
    const selectedDeviceFloorsIds = values?.floorName
    const selectedCreatedAtRange = values?.createdAt
    const selectedActiveIncidents = values?.activeIncidents
    const selectedDeviceStatus = values?.deviceStatus
    const selectedDeviceTypes = values?.deviceType
    dispatch(
      setDeviceFacilityIdsFilter(
        selectedDeviceFacilityIds?.length ? selectedDeviceFacilityIds : null
      )
    )
    dispatch(
      setDeviceFloorsIdsFilter(
        selectedDeviceFloorsIds?.length ? selectedDeviceFloorsIds : null
      )
    )
    dispatch(setDeviceFacilityCreatedAtFilter(selectedCreatedAtRange || null))
    dispatch(setDevicesHasIncidentsFilter(selectedActiveIncidents || null))
    dispatch(
      setDeviceStatusFilter(
        selectedDeviceStatus?.length ? selectedDeviceStatus : null
      )
    )
    dispatch(
      setDeviceTypeFilter(
        selectedDeviceTypes?.length ? selectedDeviceTypes : null
      )
    )
    onClose()
  }

  useEffect(() => {
    if (watchFacility && watchFacility[0]?.value) {
      const ids = watchFacility.map((e) => e.value)
      refetch({ ids })
    }

    if (watchFacility && watchFloor) {
      // Reset the floor values with only values from selected facilities
      const validFloors = getRelatedFloors(watchFacility, watchFloor)
      reset({
        ...getValues(),
        floorName: validFloors,
      })
    }
  }, [facilityIdsFilterValue, watchFacility])

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='right' size='sm'>
      <DrawerOverlay data-testid='DevicesFilterDrawer:overlay' />
      <DrawerContent>
        <DrawerHeader
          alignItems='center'
          borderBottomWidth='1px'
          borderColor='#D5DCE4'
          d='flex'
          minH='65px'
          px='5'
          py='0'
        >
          <HStack spacing='3'>
            <IconButton
              aria-label='Back button'
              borderRadius='4px'
              data-testid='devicesPage_filterDrawer_backButton'
              h='30px'
              icon={<HiArrowLeft size={18} />}
              minWidth='30px'
              onClick={onClose}
              variant='ghost'
              w='30px'
            />
            <Box fontSize='20px' fontWeight='bold' letterSpacing='-0.53px'>
              Filter Devices
            </Box>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          <Box mb='3' mt='6'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='devicesPage_filterDrawer_facilityName'
              defaultValue={
                facilityIdsFilterValue?.length ? facilityIdsFilterValue : null
              }
              id='facilityName'
              isClearable
              isMulti
              isSearchable
              label='Facility Name'
              options={facilityOptions}
              placeholder='Select Facility(-ies)'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='devicesPage_filterDrawer_floorName'
              defaultValue={
                floorsIdsFilterValue?.length ? floorsIdsFilterValue : null
              }
              id='floorName'
              isClearable
              isMulti
              isSearchable
              label='Floor Name'
              options={validFloorOptions}
              placeholder='Select Floor(s)'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              control={control}
              dataTestId='devicesPage_filterDrawer_createdAt'
              defaultValue={createdAtFilterValue || null}
              id='createdAt'
              isClearable
              isSearchable={false}
              label='Created At'
              options={createdAtOptions}
              placeholder='Select Time Range'
            />
          </Box>
          <Box mb={3}>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='devicesPage_filterDrawer_deviceStatus'
              defaultValue={deviceStatusFilterValue || null}
              id='deviceStatus'
              isClearable
              isMulti
              isSearchable
              label='Device Status'
              options={deviceStatusOptions}
              placeholder='Select Device Status(-es)'
            />
          </Box>
          <Box mb='3'>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='devicesPage_filterDrawer_activeIncidents'
              defaultValue={activeIncidentsFilterValue || null}
              id='activeIncidents'
              isClearable
              isSearchable={false}
              label='Active Incidents'
              options={activeIncidentsOptions}
              placeholder='Select Active Incidents Status'
            />
          </Box>
          <Box mb={3}>
            <FormInputSelectControl
              closeMenuOnSelect={false}
              control={control}
              dataTestId='devicesPage_filterDrawer_deviceType'
              defaultValue={
                deviceTypesFilterValue?.length ? deviceTypesFilterValue : null
              }
              id='deviceType'
              isClearable
              isMulti
              isSearchable
              label='Device Type'
              options={deviceTypeOptions}
              placeholder='Select Device Type(s)'
            />
          </Box>
        </DrawerBody>
        <DrawerFooter borderColor='#D5DCE4' borderTopWidth='1px'>
          <Button
            data-testid='devicesPage_filter_applyButton'
            isFullWidth
            onClick={handleSubmit(onSubmit)}
          >
            Apply Filters
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
