import { useFacilitiesModals } from '../hooks/useFacilitiesModals'

import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { Empty } from '@/components/icons'
import { FacilityListType, FacilityType } from '@/pages/facilities'

import { FacilitiesTableModal } from '../facilities-table-modal'
import { FacilityRowCard } from './FacilityRowCard'

interface IProps {
  facilityList: FacilityListType
  filtersApplied: boolean
  loading: boolean
  openFloorPlan: (facility: FacilityType) => void
}

export const FacilitiesTable = ({
  facilityList,
  filtersApplied,
  loading,
  openFloorPlan,
}: IProps) => {
  const { isFacilitiesTableModalOpen, modal, onFacilitiesTableModalClose } =
    useFacilitiesModals()

  return (
    <Box h='calc(100vh - 138px)' overflow='scroll' p='6'>
      <Box h='full' m='auto' maxW='1100px'>
        {loading ? (
          <VStack align='stretch' spacing='6'>
            <FacilityRowCard isLoaded={false} />
            <FacilityRowCard isLoaded={false} />
            <FacilityRowCard isLoaded={false} />
          </VStack>
        ) : facilityList.length > 0 ? (
          <VStack align='stretch' pb='6' spacing='6'>
            <AnimatePresence>
              {facilityList.map((f) => (
                <Box
                  animate={{ opacity: 1 }}
                  as={motion.div}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  key={f?.node?.id}
                  w='full'
                >
                  <FacilityRowCard
                    ac={f?.node?.doors?.totalCount}
                    address={f?.node?.address?.join(', ')}
                    cameras={f?.node?.cameras?.totalCount ?? 0}
                    devices={f?.node?.devices?.totalCount ?? 0}
                    floors={f?.node?.floors?.totalCount ?? 0}
                    guards={f?.node?.guardCount ?? 0}
                    handleFacilityClick={() => {
                      openFloorPlan(f?.node)
                    }}
                    id={f?.node?.id}
                    name={f?.node?.name}
                    shortName={f?.node.shortName}
                    siteSnapshot={f?.node?.siteSnapshot}
                    type={f?.node?.type}
                    unresolvedIncidents={f?.node?.incidents?.totalCount ?? 0}
                  />
                </Box>
              ))}
            </AnimatePresence>
          </VStack>
        ) : (
          <Flex alignItems='center' h='full' justifyContent='center'>
            <Flex
              alignItems='center'
              animate={{ opacity: 1 }}
              as={motion.div}
              exit={{ opacity: 0 }}
              flexDir='column'
              initial={{ opacity: 0 }}
              textAlign='center'
            >
              <Empty />
              <Text color='#738496' fontSize='24px' fontWeight='500'>
                {filtersApplied
                  ? 'Oops, no facilities found.'
                  : 'Create a Facility to begin!'}
              </Text>
            </Flex>
          </Flex>
        )}
      </Box>
      {isFacilitiesTableModalOpen && (
        <FacilitiesTableModal
          facilityId={modal?.facilityId}
          isOpen={isFacilitiesTableModalOpen}
          modalType={modal?.type}
          onClose={onFacilitiesTableModalClose}
        />
      )}
    </Box>
  )
}
