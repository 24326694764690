import { useFacilitySiteSnapshot } from '../hooks/useFacilitySiteSnapshot'

import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Spinner } from '@/components/ui'

interface FacilitySnapshotModalIProps {
  isOpen: boolean
  onClose: () => void
  facilityId: string
}

export const FacilitySnapshotModal = ({
  isOpen,
  facilityId,
  onClose,
}: FacilitySnapshotModalIProps) => {
  const { snapShotUrl, isLoading, error } = useFacilitySiteSnapshot(facilityId)
  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='6xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff' padding='20px' rounded='2xl'>
        <ModalHeader>
          <ModalCloseButton color='#000' mt='5px' opacity={0.4} />
        </ModalHeader>
        <ModalBody p={0}>
          {error ? (
            <Box
              alignContent='center'
              fontWeight='medium'
              height='656px'
              textAlign='center'
            >
              Error loading Site Snapshot
            </Box>
          ) : isLoading ? (
            <Spinner height={656} size='lg' />
          ) : (
            <Image
              fallback={<Spinner height={656} size='lg' />}
              height='656px'
              objectFit='contain'
              src={snapShotUrl}
              width='100%'
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
