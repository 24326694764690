import { type MouseEvent, useMemo } from 'react'

import { useDataBounds } from '@/hooks/useDataBounds'
import { useNavigation } from '@/hooks/useNavigation'
import { useWindowSize } from '@react-hook/window-size'
import { useDispatch, useSelector } from 'react-redux'

import { AnimatePresence } from 'framer-motion'

import {
  IncidentsByMarketFacilityQuery,
  OrganizationQuery,
} from '@/graphql/generated/operations'
import {
  openIncidentDrawer,
  selectSideNavOpen,
  setIncident,
} from '@/redux/ui/uiSlice'

import { dataToGeoJSONPoint, getFacilitiesPoints } from '../mapbox'
import { MapBox } from '../mapbox/components/MapBox'

interface GlobalMapIProps {
  facilitiesData: OrganizationQuery
  marketFacilityIncidentsData: IncidentsByMarketFacilityQuery
  openEditFacility: (
    id: string,
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const GlobalMap = ({
  facilitiesData,
  marketFacilityIncidentsData,
  openEditFacility,
}: GlobalMapIProps) => {
  const dispatch = useDispatch()
  const { goToFloorPlan } = useNavigation()
  const [width, height] = useWindowSize()
  const { setDataBounds } = useDataBounds()
  const isSideNavOpen = useSelector(selectSideNavOpen)

  const facilitiesPoints = useMemo(
    () => getFacilitiesPoints(facilitiesData),
    [facilitiesData]
  )

  const openFloorPlan = (floorId: string) => {
    if (floorId) {
      goToFloorPlan(floorId)
    }
  }

  const dataPoints = useMemo(
    () => dataToGeoJSONPoint(facilitiesData, marketFacilityIncidentsData),
    [facilitiesData, marketFacilityIncidentsData]
  )

  const incidentMarkerClick = (id: string) => {
    dispatch(setIncident({ id }))
    dispatch(openIncidentDrawer())
  }
  return (
    <AnimatePresence>
      {!!width && !!height && (
        <MapBox
          facilitiesPoints={facilitiesPoints}
          features={dataPoints}
          height={height - 65}
          onFacilityClick={openFloorPlan}
          onFacilityEditClick={openEditFacility}
          onIncidentMarkerClick={incidentMarkerClick}
          setDataBounds={setDataBounds}
          width={width - (isSideNavOpen ? 240 : 60)}
        />
      )}
    </AnimatePresence>
  )
}
