import React, { type MouseEvent } from 'react'

import { Box } from '@chakra-ui/react'

import { FacilityPointIProps } from '../../mapbox/types/types'
import { sortFacilitiesByStatus } from '../utils/sortFacilities'
import { BackgroundOverlay } from './BackgroundOverlay'
import { CenteredCluster } from './CenteredCluster'
import { CircularMenuCloseButton } from './CircularMenuCloseButton'
import { CircularMenuItems } from './CircularMenuItems'
import { ConnectingLines } from './ConnectingLines'

interface CircularMenuIProps {
  isOpen: boolean
  onClose: () => void
  position: { x: number; y: number }
  facilities: FacilityPointIProps[]
  onFacilityClick: (facilityId: string) => void
  openEditFacility?: (
    d: string,
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const CircularMenu: React.FC<CircularMenuIProps> = ({
  isOpen,
  onClose,
  position,
  facilities,
  onFacilityClick,
  openEditFacility,
}) => {
  if (!isOpen || facilities.length === 0) return null

  const { ordered: orderedFacilities } = sortFacilitiesByStatus(facilities)

  const facilityCount = facilities.length
  const incidentCount = facilities.reduce(
    (sum, f) => sum + (f.incidentCount ?? 0),
    0
  )

  return (
    <Box
      height='100vh'
      left={-10}
      pointerEvents='none'
      position='fixed'
      top={-10}
      width='100vw'
      zIndex={9999}
    >
      <Box
        left={position.x}
        pointerEvents='auto'
        position='absolute'
        top={position.y}
      >
        <BackgroundOverlay itemCount={orderedFacilities.length} />
        <CenteredCluster
          facilityCount={facilityCount}
          incidentCount={incidentCount}
          itemCount={orderedFacilities.length}
        />
        <ConnectingLines orderedFacilities={orderedFacilities} />
        <CircularMenuCloseButton
          itemCount={orderedFacilities.length}
          onClose={onClose}
        />
        <CircularMenuItems
          onClose={onClose}
          onFacilityClick={onFacilityClick}
          openEditFacility={openEditFacility}
          orderedFacilities={orderedFacilities}
        />
      </Box>
    </Box>
  )
}
