import { HiX } from 'react-icons/hi'

import { IconButton } from '@chakra-ui/react'

import { getCloseButtonOverlaySize } from '../utils/overlaySize'

interface CircularMenuCloseButtonIProps {
  onClose: () => void
  itemCount: number
}

export const CircularMenuCloseButton = ({
  onClose,
  itemCount,
}: CircularMenuCloseButtonIProps) => {
  const dynamicOverlaySize = getCloseButtonOverlaySize(itemCount)

  return (
    <IconButton
      _active={{ bg: 'gray.200' }}
      _hover={{ bg: 'gray.100' }}
      aria-label='Close menu'
      bg='white'
      borderRadius='full'
      boxShadow='lg'
      color='gray.800'
      icon={<HiX size={20} />}
      onClick={onClose}
      position='absolute'
      right={-dynamicOverlaySize / 2 + 40}
      size='sm'
      top={-dynamicOverlaySize / 2 + 20}
      zIndex={3}
    />
  )
}
