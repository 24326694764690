import { type MouseEvent, useEffect, useRef, useState } from 'react'

import { FeatureFlag, useFeatureFlags } from '@/hooks/useFeatureFlags'
import { useLocalState } from '@/hooks/useLocalState'
import ReactMapGL, { ViewStateChangeEvent } from 'react-map-gl'
import useSupercluster from 'use-supercluster'

import type { Feature } from 'geojson'

import {
  FacilityMarker,
  IncidentMarker,
  MapCluster,
} from '@/features/global-map'

import { ViewportStateIProps } from '..'
import { CircularMenu } from '../../circular-menu'
import {
  ClusterPointIProps,
  FacilityPointIProps,
  MarketIncidentPointIProps,
  PointType,
} from '../types/types'
import { clusterOptions } from '../utils/clusters'
import {
  MAP_STYLE,
  MAX_ZOOM,
  calculateBoundingViewport,
  defaultViewport,
  isDefaultViewport,
} from '../utils/viewport'

export interface MapBoxIProps {
  features: Feature[]
  facilitiesPoints?: [number, number][]
  setDataBounds?: (bounds: string) => void
  height?: number
  width?: number
  children?: React.ReactNode
  onIncidentMarkerClick?: (id: string) => void
  onFacilityEditClick?: (
    id: string,
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
  onFacilityClick?: (floorId: string) => void
}

interface RadialMenuStateIProps {
  isOpen: boolean
  position: { x: number; y: number }
  facilities: FacilityPointIProps[]
}

export const MapBox = ({
  features,
  facilitiesPoints,
  height = 500,
  width = 800,
  setDataBounds,
  onIncidentMarkerClick,
  onFacilityClick,
  onFacilityEditClick,
}: MapBoxIProps) => {
  const mapRef = useRef(null)
  const [viewport, setViewport] = useLocalState<ViewportStateIProps | null>(
    'viewport',
    defaultViewport
  )
  const [circularMenu, setCircularMenu] = useState<RadialMenuStateIProps>({
    isOpen: false,
    position: { x: 0, y: 0 },
    facilities: [],
  })
  const flags = useFeatureFlags()

  const isCircularMenuEnabled = flags?.[FeatureFlag.development]

  useEffect(() => {
    if (facilitiesPoints && isDefaultViewport(viewport)) {
      setViewport(calculateBoundingViewport(facilitiesPoints, width, height))
    }
  }, [facilitiesPoints])

  const handleContextMenu = (
    e: React.MouseEvent,
    facilities: FacilityPointIProps[]
  ) => {
    e.preventDefault()
    e.stopPropagation()

    setCircularMenu({
      isOpen: facilities.length >= 1 ? true : false,
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      facilities,
    })
  }

  const closeCircularMenu = () => {
    setCircularMenu((prev) => ({ ...prev, isOpen: false }))
  }

  const handleMapClick = () => {
    if (circularMenu.isOpen) {
      closeCircularMenu()
    }
  }

  const onViewportChange = (evt: ViewStateChangeEvent) => {
    if (!isDefaultViewport(evt?.viewState) && facilitiesPoints) {
      setViewport(evt?.viewState)
    }
  }

  const updateDataBounds = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getMap().getBounds()
      setDataBounds(JSON.stringify(bounds.toArray()))
    }
  }

  const onClusterClick = (id: string, lat: number, lng: number) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(id),
      MAX_ZOOM
    )
    mapRef.current?.flyTo({
      center: { lng, lat },
      zoom: expansionZoom,
      duration: 400,
    })
  }

  const { clusters, supercluster } = useSupercluster({
    points: features,
    bounds: mapRef?.current?.getMap()?.getBounds()?.toArray()?.flat() || [],
    zoom: viewport?.zoom,
    options: clusterOptions,
  })

  return (
    <ReactMapGL
      {...viewport}
      doubleClickZoom={!circularMenu.isOpen}
      dragPan={!circularMenu.isOpen}
      dragRotate={!circularMenu.isOpen}
      keyboard={!circularMenu.isOpen}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN}
      onClick={handleMapClick}
      onLoad={updateDataBounds}
      onMove={onViewportChange}
      ref={mapRef}
      scrollZoom={!circularMenu.isOpen}
      style={{ height, width }}
    >
      {clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
          point_count: pointCount,
          incidentCount,
          facilityCount,
          type,
        }: ClusterPointIProps = cluster.properties

        if (isCluster) {
          const clusterFacilities = supercluster
            .getLeaves(cluster.id, Infinity)
            .map((leaf) => leaf.properties)
            .filter((prop) => prop.type === PointType.Facility)
          return (
            <MapCluster
              coordinates={{
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
              }}
              facilityCount={facilityCount}
              hasFacilityIncidents={incidentCount > 0}
              key={cluster.id}
              marketIncidentCount={pointCount - facilityCount}
              onClick={() => onClusterClick(cluster.id, latitude, longitude)}
              onContextMenu={(e) => handleContextMenu(e, clusterFacilities)}
            />
          )
        }

        // we have a single point to render
        if (type === PointType.Facility) {
          const facilityPoint: FacilityPointIProps = cluster.properties
          return (
            <FacilityMarker
              facility={facilityPoint}
              key={cluster.id}
              onPress={() => {
                onFacilityClick(facilityPoint?.floorId)
              }}
              openEditFacility={(m) =>
                onFacilityEditClick(facilityPoint?.id, m)
              }
            />
          )
        }
        if (type === PointType.MarketIncident) {
          const incidentMarketPoint: MarketIncidentPointIProps =
            cluster.properties
          return (
            <IncidentMarker
              incident={incidentMarketPoint}
              key={cluster.id}
              onClick={() => onIncidentMarkerClick(incidentMarketPoint?.id)}
            />
          )
        }
      })}

      {isCircularMenuEnabled ? (
        <CircularMenu
          facilities={circularMenu.facilities}
          isOpen={circularMenu.isOpen}
          onClose={closeCircularMenu}
          onFacilityClick={onFacilityClick}
          openEditFacility={onFacilityEditClick}
          position={circularMenu.position}
        />
      ) : null}
    </ReactMapGL>
  )
}
