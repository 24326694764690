import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  VStack,
} from '@chakra-ui/react'

import { Floor, Incident, IncidentIcon } from '@/components/icons'
import { IncidentDescription } from '@/components/ui'
import { useFacilityIncidentsQuery } from '@/graphql/generated/hooks'
import { DeviceType, IncidentType } from '@/graphql/generated/schemas'
import { selectMe } from '@/redux/me/meSlice'
import { openIncidentDrawer, setIncident } from '@/redux/ui/uiSlice'
import { mixpanel } from '@/utils/analytics'
import { formatDateTime } from '@/utils/formatDateTime'

interface IProps {
  facilityId: string
  isOpen: boolean
  onClose: () => void
}

const ListItemSkeleton = () => {
  return (
    <Grid
      alignItems='center'
      gap='2'
      py='2'
      templateColumns='48px 1fr'
      w='full'
    >
      <Skeleton h='48px' rounded='full' w='48px' />
      <Box>
        <Skeleton h='16px' maxW='220px' w='full' />
      </Box>
    </Grid>
  )
}

const ListItem = ({
  index,
  title,
  incidentType,
  floorName,
  deviceName,
  deviceType,
  dateTime,
  description,
  handleClick,
}: {
  index: number
  title: string
  incidentType: IncidentType
  floorName?: string
  deviceName?: string
  deviceType?: DeviceType
  dateTime?: string
  description: string
  handleClick: () => void
}) => {
  return (
    <Grid
      _hover={{
        boxShadow: 'lg',
      }}
      alignItems='center'
      border='1px solid #D5DCE4'
      boxShadow='base'
      cursor='pointer'
      data-testid={`facilitiesPage_alertsModal_item${index}`}
      onClick={handleClick}
      p='3'
      rounded='4px'
      templateColumns=' 1fr'
      transition='ease-in-out'
      transitionDuration='300ms'
      transitionProperty='all'
      w='full'
    >
      <VStack alignItems='flex-start' spacing='1'>
        <Box
          color='#2D2E41'
          d='flex'
          fontSize='14px'
          fontWeight='semibold'
          justifyContent='space-between'
          letterSpacing='-0.47px'
          lineHeight='17px'
          w='full'
        >
          <Box alignItems='center' d='flex'>
            <IncidentIcon incidentType={incidentType} size={24} />
            <Box ml='2'>{title}</Box>
          </Box>
          <Box alignItems='center' d='flex'>
            <Icon as={Floor} boxSize='18px' strokeColor='#000' />
            <Box ml='1'>{`${floorName}`}</Box>
          </Box>
        </Box>
        <Box
          color='#2D2E41'
          fontSize='11px'
          fontWeight='medium'
          letterSpacing='-0.33px'
          opacity='0.8'
        >
          {formatDateTime(dateTime)}
        </Box>
        <Box
          color='#2D2E41'
          fontSize='14px'
          fontWeight='medium'
          letterSpacing='-0.47px'
          lineHeight='17px'
          opacity='0.8'
          width='100%'
        >
          <IncidentDescription
            description={description}
            deviceName={deviceName}
            deviceType={deviceType}
            floorName={floorName}
            showButtonTag={false}
            type={incidentType}
          />
        </Box>
      </VStack>
    </Grid>
  )
}

export const FacilityAlertsModal = ({
  isOpen,
  onClose,
  facilityId,
}: IProps) => {
  const dispatch = useDispatch()
  const me = useSelector(selectMe)
  const { data, loading: isLoading } = useFacilityIncidentsQuery({
    variables: {
      id: facilityId,
    },
    fetchPolicy: 'network-only',
  })

  const openAlertIncidentDrawer = (id: string) => {
    if (data) {
      dispatch(setIncident({ id }))
      dispatch(openIncidentDrawer())
      const date = new Date().toUTCString()
      mixpanel.track('Incident List Incident Button Clicked, Facility Card', {
        user_id: me.id,
        facility_id: facilityId,
        incident_id: id,
        event_at: date,
        number_of_incidents: data?.facilityIncidents?.edges?.length,
      })
    }
  }

  return (
    <Modal
      id='facilitiesPage_alertsModal'
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      scrollBehavior='inside'
      size='md'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader borderBottom='1px solid #D5DCE4' px='4'>
          <Box alignItems='center' d='flex'>
            <Icon as={Incident} boxSize={6} strokeColor='#000' />
            <Box ml={3}>
              <Box
                color='brand.500'
                fontSize='2xl'
                fontWeight='bold'
                lineHeight='1'
              >
                Incidents
              </Box>
            </Box>
          </Box>
        </ModalHeader>
        <ModalCloseButton color='#000' mt='5px' opacity={0.4} />
        <ModalBody p='4'>
          {!data || isLoading ? (
            <>
              <ListItemSkeleton />
              <ListItemSkeleton />
              <ListItemSkeleton />
            </>
          ) : (
            <VStack spacing='2'>
              {data?.facilityIncidents?.edges?.map((a, i) => (
                <ListItem
                  dateTime={a?.node?.createdAt}
                  description={a?.node?.description}
                  deviceName={a?.node?.devices?.[0]?.name}
                  deviceType={a?.node?.devices?.[0]?.type}
                  floorName={a?.node?.floor?.name}
                  handleClick={() => openAlertIncidentDrawer(a?.node?.id)}
                  incidentType={a?.node?.type}
                  index={i}
                  key={i}
                  title={a?.node?.name}
                />
              ))}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
