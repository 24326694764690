import { Circle } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { ClusterInfo } from '../../clusters/components/ClusterInfo'
import { CENTER_HOLE_SIZE } from '../constants'
import { getCenteredClusterOverlaySize } from '../utils/overlaySize'

interface CenteredClusterIProps {
  incidentCount: number
  facilityCount: number
  itemCount: number
}

export const CenteredCluster = ({
  incidentCount,
  facilityCount,
  itemCount,
}: CenteredClusterIProps) => {
  const dynamicOverlaySize = getCenteredClusterOverlaySize(itemCount)

  return (
    <motion.div
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      initial={{ scale: 0 }}
      style={{
        position: 'absolute',
        top: -(dynamicOverlaySize / 2.3),
        left: -(dynamicOverlaySize / 2.3),
        width: dynamicOverlaySize,
        height: dynamicOverlaySize,
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
      }}
    >
      <Circle
        _after={{
          content: '""',
          pos: 'absolute',
          borderRadius: '50%',
          h: '47px',
          w: '47px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderColor: incidentCount > 0 ? '#fff' : '#4ad06c',
          borderWidth: '2px',
          borderStyle: 'solid',
        }}
        bg={incidentCount > 0 ? '#D01030' : '#fff'}
        boxShadow={
          incidentCount > 0
            ? '0 2px 7px 0 rgba(189,25,25,0.5)'
            : '4px 0 14px 0 rgba(65,71,95,0.17)'
        }
        pos='relative'
        size={`${CENTER_HOLE_SIZE}px`}
      >
        <ClusterInfo
          facilityCount={facilityCount}
          hasIncidents={incidentCount > 0}
          marketIncidentsCount={0}
        />
      </Circle>
    </motion.div>
  )
}
