import { CONNECTING_LINE_CONSTANTS } from '../constants'
import { LineCalculationParams, LineCoordinates } from '../types/types'
import { calculateAngle } from './circularPosition'

export const calculateLineCoordinates = ({
  index,
  totalItems,
  radius,
  centerHoleSize,
}: LineCalculationParams): LineCoordinates => {
  const currentAngle = calculateAngle(index, totalItems)
  const angleInRadians = (currentAngle * Math.PI) / 180

  const centerPoint =
    (radius * 2 + 50) / 2 + CONNECTING_LINE_CONSTANTS.CENTER_POINT_OFFSET

  // Calculate start and end points using the angle
  const startX =
    centerPoint +
    (centerHoleSize / 2 + CONNECTING_LINE_CONSTANTS.CENTER_PADDING) *
      Math.cos(angleInRadians)
  const startY =
    centerPoint +
    (centerHoleSize / 2 + CONNECTING_LINE_CONSTANTS.CENTER_PADDING) *
      Math.sin(angleInRadians)

  const endX =
    centerPoint +
    (radius - 50 / 2 - CONNECTING_LINE_CONSTANTS.RADIAL_PADDING) *
      Math.cos(angleInRadians)
  const endY =
    centerPoint +
    (radius - 50 / 2 - CONNECTING_LINE_CONSTANTS.RADIAL_PADDING) *
      Math.sin(angleInRadians)

  return { startX, startY, endX, endY }
}
