import React from 'react'

import { CONNECTING_LINE_CONSTANTS } from '../constants'
import { LineCoordinates } from '../types/types'

interface ConnectingLineIProps {
  coordinates: LineCoordinates
  index: number
}

export const ConnectingLine: React.FC<ConnectingLineIProps> = ({
  coordinates,
  index,
}) => {
  return (
    <line
      key={index}
      opacity={CONNECTING_LINE_CONSTANTS.STYLE.OPACITY}
      stroke={CONNECTING_LINE_CONSTANTS.STYLE.STROKE}
      strokeWidth={CONNECTING_LINE_CONSTANTS.STYLE.STROKE_WIDTH}
      x1={coordinates.startX}
      x2={coordinates.endX}
      y1={coordinates.startY}
      y2={coordinates.endY}
    />
  )
}
