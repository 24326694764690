import React, { type MouseEvent, useMemo } from 'react'

import { FacilityPointIProps } from '../../mapbox/types/types'
import { calculateCircularItemPosition } from '../utils/circularPosition'
import { CircularMenuItem } from './CircularMenuItem'

interface CircularMenuItemsIProps {
  orderedFacilities: FacilityPointIProps[]
  onClose: () => void
  onFacilityClick: (facilityId: string) => void
  openEditFacility?: (
    d: string,
    m: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

export const CircularMenuItems = ({
  orderedFacilities,
  onClose,
  onFacilityClick,
  openEditFacility,
}: CircularMenuItemsIProps) => {
  const itemsWithPositions = useMemo(() => {
    return orderedFacilities.map((facility, index) => {
      const position = calculateCircularItemPosition(
        index,
        orderedFacilities.length
      )
      return {
        facility,
        position,
      }
    })
  }, [orderedFacilities])

  return (
    <>
      {itemsWithPositions.map(({ facility, position }) => (
        <CircularMenuItem
          facility={facility}
          key={facility.id}
          onClose={onClose}
          onFacilityClick={onFacilityClick}
          openEditFacility={openEditFacility}
          position={position}
        />
      ))}
    </>
  )
}
