import { OVERLAY_SIZE, RADIUS } from '../constants'
import { OverlaySizeType } from '../types/types'

// Multiplier mapping based on count ranges and type
const multipliers: Record<OverlaySizeType, number[]> = {
  overlay: [1, 1.5, 1.8, 2.5],
  radius: [1, 1.5, 1.9, 2.5],
  closeButton: [1, 1.35, 1.55, 2.1],
  centeredCluster: [1, 1.5, 1.6, 2.5],
}

export const getDynamicSize = (
  count: number,
  type: OverlaySizeType = 'overlay'
): number => {
  const baseSize = OVERLAY_SIZE

  let multiplierIndex = 0
  if (count > 24) {
    multiplierIndex = 3
  } else if (count > 16) {
    multiplierIndex = 2
  } else if (count > 8) {
    multiplierIndex = 1
  }

  const sizeMultiplier = multipliers[type][multiplierIndex]
  return baseSize * sizeMultiplier
}

export const getDynamicOverlaySize = (count: number) =>
  getDynamicSize(count, 'overlay')

export const getDynamicRadius = (count: number) =>
  getDynamicSize(count, 'radius') / (OVERLAY_SIZE / RADIUS)

export const getCloseButtonOverlaySize = (count: number) =>
  getDynamicSize(count, 'closeButton')

export const getCenteredClusterOverlaySize = (count: number) =>
  getDynamicSize(count, 'centeredCluster')
