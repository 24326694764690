import { MENU_CONSTANTS } from '../constants'

export const getRadiusByItemCount = (itemCount: number): number => {
  const tier = Math.ceil(itemCount / MENU_CONSTANTS.ITEMS_PER_TIER)
  return (
    MENU_CONSTANTS.RADIUS_TIERS[
      tier as keyof typeof MENU_CONSTANTS.RADIUS_TIERS
    ] ?? MENU_CONSTANTS.RADIUS_TIERS[4]
  )
}

export const calculateAngle = (index: number, total: number): number => {
  let startAngle: number,
    endAngle: number,
    angleStep: number,
    baseAngle: number,
    currentAngle: number

  if (total <= 2) {
    // For 1-2 items in first quadrant
    startAngle = -115
    endAngle = 20
    angleStep = (endAngle - startAngle) / (total + 0.5)
    currentAngle = startAngle + (index + 0.5) * angleStep
  } else if (total <= 4) {
    // For 3-4 items in first two quadrants
    startAngle = -110
    endAngle = 90
    angleStep = (endAngle - startAngle) / (total + 0.5)
    currentAngle = startAngle + (index + 0.5) * angleStep
  } else {
    // Normal distribution for 5+ items
    baseAngle = -90
    angleStep = 360 / total
    currentAngle = baseAngle + index * angleStep
  }

  return currentAngle
}

export const calculateCircularItemPosition = (index: number, total: number) => {
  const radius = getRadiusByItemCount(total)
  const currentAngle = calculateAngle(index, total)
  const angleInRadians = (currentAngle * Math.PI) / 180

  return {
    x: Math.cos(angleInRadians) * radius,
    y: Math.sin(angleInRadians) * radius,
    angle: currentAngle,
  }
}
