import { motion } from 'framer-motion'

import { CENTER_HOLE_SIZE } from '../constants'
import { getDynamicOverlaySize } from '../utils/overlaySize'

interface BackgroundOverlayIProps {
  itemCount: number
}

export const BackgroundOverlay = ({ itemCount }: BackgroundOverlayIProps) => {
  const dynamicOverlaySize = getDynamicOverlaySize(itemCount)
  const overlayPos = itemCount >= 16 ? 2.2 : 2.3

  return (
    <motion.div
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0.7,
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: [0.4, 0, 0.2, 1],
        },
      }}
      initial={{
        scale: 0.5,
        opacity: 0,
      }}
      style={{
        position: 'absolute',
        top: -(dynamicOverlaySize / overlayPos),
        left: -(dynamicOverlaySize / overlayPos),
        width: dynamicOverlaySize,
        height: dynamicOverlaySize,
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      }}
    >
      <svg
        height={dynamicOverlaySize}
        style={{
          filter: 'drop-shadow(0 4px 20px rgba(0, 0, 0, 0.15))',
        }}
        width={dynamicOverlaySize}
      >
        <defs>
          <radialGradient cx='50%' cy='50%' id='menuGradient' r='50%'>
            <stop offset='0%' stopColor='rgba(18, 24, 38, 0.75)' />
            <stop offset='30%' stopColor='rgba(18, 24, 38, 0.6)' />
            <stop offset='70%' stopColor='rgba(18, 24, 38, 0.4)' />
            <stop offset='100%' stopColor='rgba(18, 24, 38, 0.1)' />
          </radialGradient>
          <filter id='softShadow'>
            <feGaussianBlur in='SourceAlpha' result='blur' stdDeviation='6' />
            <feColorMatrix
              in='blur'
              result='shadow'
              type='matrix'
              values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0'
            />
            <feBlend in='SourceGraphic' in2='shadow' mode='normal' />
          </filter>
          <mask id='donut'>
            <rect fill='white' height='100%' width='100%' />
            <circle
              cx={dynamicOverlaySize / 2}
              cy={dynamicOverlaySize / 2}
              fill='black'
              r={CENTER_HOLE_SIZE / 2}
            />
          </mask>
        </defs>
        <circle
          cx={dynamicOverlaySize / 2}
          cy={dynamicOverlaySize / 2}
          fill='url(#menuGradient)'
          filter='url(#softShadow)'
          mask='url(#donut)'
          r={dynamicOverlaySize / 2}
        />
        <circle
          cx={dynamicOverlaySize / 2}
          cy={dynamicOverlaySize / 2}
          fill='none'
          mask='url(#donut)'
          r={dynamicOverlaySize / 2 - 1}
          stroke='rgba(255, 255, 255, 0.05)'
          strokeWidth='1'
        />
      </svg>
    </motion.div>
  )
}
