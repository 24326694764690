import { useFacilitySiteSnapshotQuery } from '@/graphql/generated/hooks'
import { UrlContent } from '@/graphql/generated/schemas'

export function useFacilitySiteSnapshot(id: string) {
  const {
    data,
    loading: isLoading,
    error,
  } = useFacilitySiteSnapshotQuery({
    variables: { id },
    skip: !id,
  })
  const siteSnapshot = data?.facility?.siteSnapshot as UrlContent

  return {
    error,
    isLoading,
    snapShotUrl: siteSnapshot?.url,
  }
}
