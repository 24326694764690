import { useIncidentDescription } from '../../feed/hooks/useIncidentDescription'

import { PDFForm, PDFPage, rgb } from 'pdf-lib'

import {
  DeviceType,
  IncidentActionType,
  IncidentType,
} from '@/graphql/generated/schemas'

import { IncidentNoteType, NonActionItem } from '../../feed/types/types'
import { TimelineAction } from '../types/types'

export const getActionPerformed = (
  actionType: IncidentActionType | NonActionItem
) => {
  switch (actionType) {
    case IncidentActionType.AcknowledgementByGuard:
    case IncidentActionType.AcknowledgementByOperator:
      return 'acknowledged the incident.'

    case IncidentActionType.OnScene:
      return 'is on scene.'

    case IncidentActionType.ReleaseByOperator:
    case IncidentActionType.ReleaseByGuard:
      return 'released the incident.'

    case IncidentActionType.Cleared:
      return 'cleared the incident.'

    case IncidentActionType.Resolved:
      return 'resolved the incident.'

    case IncidentActionType.OrderDeviceRepair:
      return 'contacted technician.'

    case IncidentActionType.SetRealToFalse:
      return 'marked the incident as false.'

    case IncidentActionType.SetRealToTrue:
      return 'marked the incident as real.'

    case IncidentActionType.StartEmergencyCall:
      return 'called Emergency Number'

    case IncidentActionType.EndEmergencyCall:
      return `'s Emergency Call ended'`

    case IncidentActionType.TakeoverByGuard:
      return 'took over the incident.'

    case IncidentActionType.Flag:
      return 'has flagged the incident for further review and analysis.'

    case NonActionItem.IncidentNote:
      return 'added a note:'

    case NonActionItem.AudioMessage:
      return 'sent a voice message.'

    default:
      return ''
  }
}

export const calculateResolutionTimeInDays = (
  resolvedAt: string,
  createdAt: string
): string => {
  const resolutionTimeInMs =
    new Date(resolvedAt).getTime() - new Date(createdAt).getTime()

  const seconds = Math.floor((resolutionTimeInMs / 1000) % 60)
  const minutes = Math.floor((resolutionTimeInMs / 1000 / 60) % 60)
  const hours = Math.floor((resolutionTimeInMs / 1000 / 60 / 60) % 24)

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':')
}

export const getSplitImages = (
  imageUrls: string[],
  notes: IncidentNoteType[]
) => {
  const imagesFromNotes = notes
    .filter((note) => note.media?.length > 0)
    .map((note) => note.media)
    .flat()
    .filter(
      (media) =>
        media.mimeType === 'image/jpeg' ||
        media.mimeType === 'image/png' ||
        media.mimeType === 'image/jpg'
    )
    .map((media) => media.url)

  const incidentImages = imageUrls.concat(imagesFromNotes)

  const leftHalfImages = incidentImages.filter(
    (_: string, i: number) => i % 2 === 0
  )
  const rightHalfImages = incidentImages.filter(
    (_: string, i: number) => i % 2 !== 0
  )

  return { leftHalfImages, rightHalfImages }
}

export const getIncidentDescription = (
  type: IncidentType,
  description: string,
  floorName: string,
  floorId: string,
  deviceName: string,
  deviceType: DeviceType,
  deviceId: string
) => {
  const { message } = useIncidentDescription({
    deviceId,
    deviceType,
    floorId,
    type,
  })

  switch (type) {
    case IncidentType.DoorHeldOpen:
    case IncidentType.DoorHeldClose:
    case IncidentType.ForcedEntry:
    case IncidentType.LineCrossing:
    case IncidentType.Alarm:
      return `${deviceName} on floor ${floorName} ${message}`

    case IncidentType.Tailgating:
      return message

    default:
      return description
  }
}

export const getFieldAppearanceOptions = (
  page: PDFPage,
  x: number,
  y: number
) => {
  return {
    x,
    y,
    width: page.getWidth() - 80,
    height: 135,
    borderWidth: 0,
    //pdf-lib rgb values can only be between 0 and 1
    backgroundColor: rgb(210 / 255, 228 / 255, 255 / 255),
  }
}

export const createPDFTextField = (
  form: PDFForm,
  fieldName: string,
  text: string
) => {
  const field = form.createTextField(fieldName)
  field.setText(text)
  field.enableMultiline()
  field.enableScrolling()
  return field
}

export const isNotAssignment = (action: TimelineAction) =>
  action.__typename === NonActionItem.SystemAction &&
  !action.systemMessage.includes('assign')

export const userAction = (action: TimelineAction) => {
  if (
    action.__typename === NonActionItem.AudioMessage ||
    action.__typename === NonActionItem.IncidentNote ||
    action.__typename === 'IncidentAction'
  ) {
    return action
  }
  return null
}

export const isAudioOrNote = (action: TimelineAction) =>
  action.__typename === NonActionItem.IncidentNote ||
  action.__typename === NonActionItem.AudioMessage

export const shouldDisplayDateForAction = (action: TimelineAction) =>
  (action.__typename === NonActionItem.SystemAction &&
    !action.systemMessage.includes('assign')) ||
  action.__typename !== NonActionItem.SystemAction

export const isIncidentNote = (action: TimelineAction) =>
  action.__typename === NonActionItem.IncidentNote
