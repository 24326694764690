import React, { useMemo } from 'react'

import { FacilityPointIProps } from '../../mapbox/types/types'
import { CENTER_HOLE_SIZE } from '../constants'
import { calculateLineCoordinates } from '../utils/lineCoordinates'
import { getDynamicOverlaySize, getDynamicRadius } from '../utils/overlaySize'
import { ConnectingLine } from './ConnectingLine'

interface ConnectingLinesIProps {
  orderedFacilities: FacilityPointIProps[]
}

export const ConnectingLines = ({
  orderedFacilities,
}: ConnectingLinesIProps) => {
  const dynamicRadius = getDynamicRadius(orderedFacilities.length)
  const svgSize = dynamicRadius * 2 + 50
  const dynamicOverlaySize = getDynamicOverlaySize(orderedFacilities.length)

  const lineCoordinates = useMemo(() => {
    return orderedFacilities.map((_, index) => ({
      coordinates: calculateLineCoordinates({
        index,
        totalItems: orderedFacilities.length,
        radius: dynamicRadius,
        centerHoleSize: CENTER_HOLE_SIZE,
      }),
      index,
    }))
  }, [orderedFacilities, dynamicRadius])

  return (
    <svg
      style={{
        position: 'absolute',
        top: dynamicOverlaySize / 20.5,
        left: dynamicOverlaySize / 20.5,
        transform: 'translate(-50%, -50%)',
        width: svgSize,
        height: svgSize,
        pointerEvents: 'none',
        zIndex: 1,
      }}
    >
      {lineCoordinates.map(({ coordinates, index }) => (
        <ConnectingLine coordinates={coordinates} index={index} key={index} />
      ))}
    </svg>
  )
}
