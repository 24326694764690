export const RADIUS = 110
export const OVERLAY_PADDING = 60
export const OVERLAY_SIZE = (RADIUS + OVERLAY_PADDING) * 2
export const CENTER_HOLE_SIZE = 60

export const CONNECTING_LINE_CONSTANTS = {
  BASE_ANGLE: -90,
  CENTER_PADDING: 10,
  RADIAL_PADDING: 11,
  CENTER_POINT_OFFSET: 5,
  STYLE: {
    STROKE: 'white',
    STROKE_WIDTH: 2,
    OPACITY: 1,
  },
} as const

export const MENU_CONSTANTS = {
  ITEMS_PER_TIER: 8,
  RADIUS_TIERS: {
    1: 110, // 1-8 items
    2: 170, // 9-16 items
    3: 230, // 17-24 items
    4: 290, // 25-32 items
  },
  OVERLAY_PADDING: 60,
  CENTER_HOLE_SIZE: 60,
} as const
