import { FacilityPointIProps } from '../../mapbox/types/types'
import { SortedFacilities } from '../types/types'

export const sortFacilitiesByStatus = (
  facilities: FacilityPointIProps[]
): SortedFacilities => {
  const withIncidents = facilities.filter((f) => f.incidentCount > 0)
  const withoutIncidents = facilities.filter((f) => f.incidentCount === 0)

  return {
    withIncidents,
    withoutIncidents,
    ordered: [...withIncidents, ...withoutIncidents],
  }
}
